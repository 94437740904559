import React, { useState, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { graphql, Link, useStaticQuery } from "gatsby";
import ThemeToggle from "./themeToggle";
import { Bars3Icon, CubeTransparentIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { solutions } from "./links";
import { callsToAction } from "./links";

import Logo from "../../images/logo.inline.svg";

import Tour from "../tour";

import { classNames } from "../../helpers/functions";
import ButtonGitHub from "./buttonGitHub";
import MobileNav from "./mobileNav";

const Navigation = ({ navigationBorder, theme }) => {
  let iconData = useStaticQuery(graphql`
    {
      allContentfulAsset(
        filter: {
          metadata: { tags: { elemMatch: { name: { eq: "solutions" } } } }
        }
      ) {
        edges {
          node {
            title
            svg {
              content
            }
          }
        }
      }
    }
  `);
  const [joyRun, setJoyrun] = useState(false);

  // const themeToggleElement = (
  //   <ThemeToggle key="desktop_theme_toggle" theme={theme} className="mr-5" />
  // );

  // function themeToggleFn(mobile = false) {

  //   if (mobile) { } else {
  //     <ThemeToggle key="desktop_theme_toggle" theme={theme} className="mr-5" />;
  //   }

  // }

  return (
    <div
      key="main-nav-popover-holder"
      className="relative left-0 right-0 z-20 mt-4 lg:mx-auto xl:ml-[calc((100vw-512px)/4)] xl:mr-0 max-w-none lg:max-w-2xl lg:rounded-[50px] lg:bg-white lg:shadow-lg dark:lg:bg-slate-900 bg-opacity-100 dark:bg-opacity-100"
    >
      {/* <div className="absolute left-0 z-0" */}
      <Popover key="main-nav-popover" className="relative tour-navigation">
        <div
          className={` flex border-slate-100 dark:border-slate-900 dark:border-opacity-10 px-4 lg:px-10 ${
            navigationBorder ? "border-b-2" : ""
          }`}
        >
          <div className="flex-1 flex items-center self-center justify-between md:justify-start md:space-x-10 my-auto">
            <div className="flex justify-start align-middle flex-1">
              <Link
                to="/"
                className="max-w-[140px] xl:max-w-[160px] min-w-[120px]"
              >
                <span className="sr-only">Xneda</span>
                <Logo className="w-full" />
              </Link>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white dark:bg-slate-800 dark:bg-opacity-20 p-2 text-slate-400 hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-800">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-8 w-8" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden space-x-10 md:flex">
              <Link
                to="/portfolio"
                className="text-base font-medium text-slate-800 dark:text-slate-400 hover:text-slate-900 dark:hover:text-slate-300 py-4"
                activeClassName="text-slate-900 dark:!text-slate-300"
              >
                Portfolio
                <span className="dark:text-slate-900 hiden"></span>
              </Link>
              <Popover key="inde-main-nav-popover-2" className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open
                          ? "text-slate-900 dark:text-slate-300"
                          : "text-slate-800 dark:text-slate-400",
                        "group inline-flex items-center rounded-md text-base font-medium hover:text-slate-700 dark:hover:text-slate-300 focus:outline-none py-4"
                      )}
                    >
                      <span>Solutions</span>
                      <ChevronDownIcon
                        className={classNames(
                          open
                            ? "text-slate-700 dark:text-slate-200"
                            : "text-slate-500 dark:slate-slate-400",
                          "ml-2 h-5 w-5 group-hover:text-slate-700 dark:group-hover:text-slate-200"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-slate-900 ring-opacity-5">
                          <div className="relative grid gap-6 bg-white dark:bg-slate-900 px-5 py-6 sm:gap-8 sm:p-8">
                            {solutions.map(item => (
                              <Link
                                key={`dan-${item.name}`}
                                to={item.href}
                                className="-m-3 flex items-start rounded-lg p-3 hover:bg-slate-50 dark:hover:bg-black dark:hover:bg-opacity-20"
                              >
                                {/* Get the solution Icon */}
                                {iconData &&
                                  iconData.allContentfulAsset.edges.map(
                                    ({ node: { title, svg } }) => {
                                      if (title === item.icon)
                                        return (
                                          <div
                                            key={`icon-${title}`}
                                            className="w-10 mb-4"
                                            dangerouslySetInnerHTML={{
                                              __html: svg.content,
                                            }}
                                          />
                                        );

                                      return false;
                                    }
                                  )}
                                <div className="ml-4">
                                  <p className="text-base font-medium text-slate-900 dark:text-slate-300">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-slate-500">
                                    {item.description}
                                  </p>
                                </div>
                              </Link>
                            ))}
                          </div>
                          <div className="space-y-6 bg-slate-50 dark:bg-slate-900 dark:border-t-2 dark:border-t-black dark:border-opacity-[15%] px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                            {callsToAction.map(item => (
                              <div
                                key={item.name}
                                className="flex flex-1 justify-between"
                              >
                                <a
                                  href={item.href}
                                  className="flex items-center rounded-md p-3 text-base font-medium text-slate-900 dark:text-slate-400 hover:bg-slate-100 dark:hover:bg-black dark:hover:bg-opacity-20"
                                >
                                  <item.icon
                                    className="h-6 w-6 flex-shrink-0"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3">{item.name}</span>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Link
                to="/our-story"
                className="text-base font-medium text-slate-800 dark:text-slate-400 hover:text-slate-700 dark:hover:text-slate-300 py-4"
                activeClassName="text-slate-700 dark:!text-slate-300"
              >
                Our story
              </Link>
            </Popover.Group>
            <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
              {/* <a
                  href="#"
                  onClick={({ nativeEvent }) => {
                    nativeEvent.preventDefault();
                    return setJoyrun(true);
                  }}
                  className="ml-8 inline-flex items-center justify-center whitespace-nowrap px-2 py-2 text-xs rounded-lg ease-in hover:ease-in-out duration-300 hover:bg-slate-100"
                  title="explore details of components"
                >
                  <CubeTransparentIcon className="h-6 w-6" aria-hidden="true" />
                </a> */}
              <ThemeToggle
                key="desktop_theme_toggle"
                id="desktop_theme_toggle"
                theme={theme}
                className="mr-5"
              />
              <ButtonGitHub />
              {/* <Tour run={joyRun} setJoyrun={setJoyrun} /> */}
            </div>
          </div>
        </div>

        <MobileNav key="mobile_nav" iconData={iconData} theme={theme} />
      </Popover>
    </div>
  );
};

export default Navigation;
