import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Image from "./image";

const HeroGalleryImages = props => {
  const { location } = props;

  const [heroImage, setHeroImage] = useState(null);
  const [lastHeroImage, setLastHeroImage] = useState({
    timeout: 3000,
    stop: true,
  });

  const data = useStaticQuery(graphql`
    query {
      heroGalleryImages: allContentfulHeroGallery(
        filter: { node_locale: { eq: "en" } }
      ) {
        edges {
          node {
            id
            name
            isSpecialDay
            startDateTimestamp
            endDateTimestamp

            focalPoint {
              image {
                title
                description
                gatsbyImageData(
                  formats: [AUTO, AVIF, WEBP]
                  quality: 90
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  `);

  const { heroGalleryImages } = data;

  console.log("hottori", heroGalleryImages.edges);

  React.useEffect(() => {
    console.log("useEffect with []");
    let storageLastHeroGalleryItem = JSON.parse(
      sessionStorage.getItem("lastGalleryItem")
    );

    if (storageLastHeroGalleryItem) {
      if (heroGalleryImages) {
        let storageLastHeroGalleryItemTemp = false;
        let edges = heroGalleryImages.edges;
        if (edges)
          storageLastHeroGalleryItemTemp = edges.find(({ node }) => {
            // console.log("hero", node);
            return node.id === storageLastHeroGalleryItem.id;
          });

        if (storageLastHeroGalleryItemTemp) {
          storageLastHeroGalleryItem = storageLastHeroGalleryItemTemp.node;
        } else {
          storageLastHeroGalleryItem = edges[0].node;
        }
      }

      console.log(
        "useEffect with [] storageLastHeroGalleryItem",
        storageLastHeroGalleryItem
      );

      sessionStorage.setItem(
        "lastGalleryItem",
        JSON.stringify(storageLastHeroGalleryItem)
      );
      setBgPicture(storageLastHeroGalleryItem);

      setLastHeroImage({
        timeout: 3000,
        stop: true,
      });
    } else {
      setLastHeroImage({
        timeout: 0,
        stop: false,
      });
      setBgPicture(false);
    }
  }, []);

  React.useEffect(() => {
    console.log("useEffect with [location.href]");
    let timeout;

    console.log("lastHeroImage", lastHeroImage);
    if (!lastHeroImage.stop) {
      timeout = setTimeout(() => {
        setBgPicture(false);
      }, lastHeroImage.timeout);
    }
    setLastHeroImage({
      stop: false,
      timeout: 3000,
    });
    return () => clearTimeout(timeout);
  }, [location.href]);

  const setBgPicture = item => {
    let heroBgImage;
    if (item) {
      heroBgImage = item;
    } else {
      const filteredHeroBgImages = prepareHeroBgImages(heroGalleryImages);

      heroBgImage = getSetHeroBgImage(filteredHeroBgImages);

      //   console.log("filteredHeroBgImages", filteredHeroBgImages);
    }

    setHeroImage(heroBgImage);
  };
  const prepareHeroBgImages = ({ edges }) => {
    let nonSpecialDayItems = [];
    let specialDayItems = [];
    let currentDateTimestamp = new Date().getTime();

    //if there is/are a special date item(s) than set bgImageArr as them
    edges.map(item => {
      const { node } = item;
      if (node.isSpecialDay) {
        if (
          node.startDateTimestamp <= currentDateTimestamp &&
          node.endDateTimestamp >= currentDateTimestamp
        )
          specialDayItems.push(node);
      } else {
        nonSpecialDayItems.push(node);
      }

      // return node;
    });

    return specialDayItems.length > 0 ? specialDayItems : nonSpecialDayItems;
  };

  const cleanHeroBgImageStorage = heroBgImages => {
    let storedItems = JSON.parse(localStorage.getItem("showedGalleryItems"));
    if (storedItems && storedItems.length > 0) {
      storedItems = storedItems.filter(function (entry1) {
        return heroBgImages.some(function (entry2) {
          return entry1.id === entry2.id;
        });
      });

      localStorage.setItem("showedGalleryItems", JSON.stringify(storedItems));
    }
  };

  const getSetHeroBgImage = heroBgImages => {
    //clean storage first
    cleanHeroBgImageStorage(heroBgImages);
    let result;
    //if there is only one item in the array do not store or do sth else just return it
    if (heroBgImages.length === 1) return heroBgImages[0];

    let storedItems = JSON.parse(localStorage.getItem("showedGalleryItems"));

    if (!storedItems || storedItems.length === 0) {
      localStorage.setItem(
        "showedGalleryItems",
        JSON.stringify([heroBgImages[0]])
      );

      result = heroBgImages[0];
    }

    if (!result) {
      const isSame = (a, b) => a.id === b.id;
      const onlyInLeft = (left, right, compareFunction) =>
        left.filter(
          leftValue => !right.some(rightValue => isSame(leftValue, rightValue))
        );

      const onlyInA = onlyInLeft(heroBgImages, storedItems);
      const onlyInB = onlyInLeft(storedItems, heroBgImages);

      const difference = [...onlyInA, ...onlyInB];

      if (difference.length === 0) {
        localStorage.setItem(
          "showedGalleryItems",
          JSON.stringify([heroBgImages[0]])
        );

        result = heroBgImages[0];
      } else {
        localStorage.setItem(
          "showedGalleryItems",
          JSON.stringify([...storedItems, ...[difference[0]]])
        );

        result = difference[0];
      }
    }

    //set last image to the localStorage
    sessionStorage.setItem("lastGalleryItem", JSON.stringify(result));

    return result;
  };

  // const staticWaveBgPic = getImage(
  //   staticWaveBg.childImageSharp.gatsbyImageData
  // );

  return (
    <>
      <Image heroImage={heroImage} {...props} />
      {/* <GatsbyImage
        className=" max-w-full min-h-[600px]"
        image={staticWaveBgPic}
        alt=""
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          zIndex: "-1",
        }}
      /> */}
    </>
  );
};

export default HeroGalleryImages;
