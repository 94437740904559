import React from "react";
import IconGitHub from "../../images/icons/github.inline.svg";

const ButtonGitHub = ({ text = false }) => {
  return (
    <a
      className="inline-flex relative items-center fill-current-important text-slate-500 dark:text-slate-400 hover:text-slate-700 dark:hover:text-slate-300 transition-colors duration-300"
      href="https://github.com/murathanakay"
      target="_blank"
      title="GitHub"
      rel="noreferrer"
    >
      <IconGitHub className="w-6 h-6" />
      {text && <span className="ml-2">GitHub</span>}
    </a>
  );
};
export default ButtonGitHub;
