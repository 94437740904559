import React, { useState, useRef, useEffect } from "react";

const ThemeToggle = props => {
  const [theme, toggleTheme] = props?.theme || null;
  const [themeState, setThemeState] = useState(false);
  const themeNotificationRef = useRef(null);

  useEffect(() => {
    if (theme && themeNotificationRef.current) {
      themeNotificationRef.current.classList.remove("hidden");
      let timer1 = setTimeout(() => {
        themeNotificationRef.current.classList.add("hidden");
      }, 5000);

      let themeToggleProgressEl = document.getElementById(
        "themeToggleProgressEl"
      );
      themeToggleProgressEl.classList.remove("animate-progress-line-w");

      setTimeout(() => {
        themeToggleProgressEl.classList.add("animate-progress-line-w");
      }, 100);

      return () => {
        clearTimeout(timer1);
      };
    }
  }, [themeState]);

  // Don't render anything at compile time. Deferring rendering until we
  // know which theme to use on the client avoids incorrect initial
  // state being displayed.
  if (theme === null) {
    return null;
  }

  return (
    <>
      <label
        htmlFor={props.id}
        className={`inline-flex relative items-center cursor-pointer ${props?.className}`}
      >
        <input
          type="checkbox"
          value=""
          id={props.id}
          className="sr-only peer"
          onChange={e => {
            const newTheme = e.target.checked ? "dark" : "light";
            toggleTheme(newTheme);

            setThemeState(newTheme);
          }}
          checked={theme === "dark"}
        />
        <div className="p-1 bg-slate-300 dark:bg-slate-400 text-slate-500 hover:text-slate-600 dark:text-slate-200 dark:hover:text-white  rounded-full">
          <svg
            id="theme-toggle-dark-icon"
            className={`w-4 h-4 ${theme === "dark" ? "hidden" : ""}`}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
          </svg>

          <svg
            id="theme-toggle-light-icon"
            className={`w-4 h-4 ${theme !== "dark" ? "hidden" : ""}`}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
              fillRule="evenodd"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        {props?.text && (
          <span
            className={`ml-2 text-slate-500 hover:text-slate-800 dark:text-slate-400`}
          >
            {theme === "dark" ? "Light Mode" : "Dark Mode"}
          </span>
        )}
      </label>

      {theme !== null && themeState && (
        <div
          ref={themeNotificationRef}
          className="absolute z-50 -bottom-7 right-2 px-2 py-1 text-xs rounded-xl text-slate-500 bg-slate-300 dark:bg-slate-800 overflow-hidden"
        >
          <div className="relative z-10">
            switched to <span className="font-bold">{themeState}</span> mode!
          </div>
          <div
            id="themeToggleProgressEl"
            className="absolute bottom-0 left-0 right-0 bg-slate-200 dark:bg-slate-900 h-full z-0 w-0"
          ></div>
        </div>
      )}
    </>
  );
};

export default ThemeToggle;
