export function isDark() {
  return (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  );
}

export function cleanUrl(url) {
  return url.replace(/^https?:\/\//, "").replace(/\/$/, "");
}
export function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function escapeHtml(string) {
  return string.replace(/<\/?[^>]+(>|$)/g, "");
}

const asyncLocalStorage = {
  setItem(key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem(key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
};

export default asyncLocalStorage;
