import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Navigation from "./Navigation";

const Header = ({ siteTitle, navigationBorder, theme }) => (
  <header className="flex flex-col justify-between">
    <Navigation navigationBorder={navigationBorder} theme={theme} />
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
