import React from "react";
import { useStaticQuery } from "gatsby";
import {
  ArrowPathIcon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  DevicePhoneMobileIcon,
  ComputerDesktopIcon,
  ChatBubbleBottomCenterIcon,
  PaintBrushIcon,
} from "@heroicons/react/24/outline";
import IconGithub from "../../images/icons/github.inline.svg";

export const solutions = [
  {
    name: "Web Development",
    description:
      "more than just pretty interfaces. We colligate beauty with functionality.",
    href: `/web-development`,
    icon: "webdevelopment",
  },
  {
    name: "Mobile Application",
    description: "marvelous mobile applications for both IOS and Android.",
    href: `/mobile-application`,
    icon: "mobileapplication",
  },
  {
    name: "UI/UX Design",
    description:
      "candied user interfaces and practical user experience for your business.",
    href: `/ui-ux-design`,
    icon: "uiuxdesign",
  },
];
export const callsToAction = [
  { name: "Get a quote", href: "#", icon: ChatBubbleBottomCenterIcon },
  { name: "Contact us", href: "#", icon: PhoneIcon },
];
export const resources = [
  {
    name: "Our Story",
    description: "Learn about our work history.",
    href: "/our-story",
    icon: LifebuoyIcon,
  },
  {
    name: "Work with us",
    description: "Let's create your digital face.",
    href: "#",
    icon: BookmarkSquareIcon,
  },
  {
    name: "Blog",
    description: "Read our blog.",
    href: "#",
    icon: CalendarIcon,
  },
];

export const mobileFooterLinks = [
  {
    id: 1,
    name: "GitHub",
    icon: (
      <>
        <IconGithub />
      </>
    ),
    href: "https://github.com/murathanakay",
    target: "_blank",
  },
];
