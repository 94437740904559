import React, { useState, useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Transition } from "@headlessui/react";

const Image = props => {
  const { heroImage } = props;
  const [theme, setTheme] = useState("light");

  // console.log("image.js", heroImage, theme);

  useEffect(() => {
    let tempTheme = localStorage.getItem("theme");
    if (tempTheme) {
      setTheme(tempTheme);
    }
  }, []);
  return (
    <>
      {heroImage ? (
        <Transition
          unmount={true}
          appear={true}
          show={true}
          enter="transition-opacity duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-1000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          style={{
            // gridArea: "1/1",
            mixBlendMode: theme === "light" ? "lighten" : "",
            background:
              heroImage.focalPoint.image.gatsbyImageData.backgroundColor,
            // filter:
            //   theme === "light"
            //     ? "grayscale(95%) brightness(95%) hue-rotate(120deg)"
            //     : "grayscale(15%) brightness(65%) hue-rotate(100deg)",
          }}
          key={heroImage.id}
          className="hero-image-container fixed left-0 right-0 -z-[1] overflow-hidden"
        >
          <>
            {/* <div
              className="absolute left-1 mt-[calc(100vh-1rem)] font-mono transition-all text-slate-400 duration-500 hover:!text-xs hover:text-slate-500 z-10"
              style={{
                fontSize: "6px",
              }}
            > */}
            {/* {heroImage.focalPoint.image.description} */}
            {/* </div> */}
            <GatsbyImage
              className="max-h-screen max-w-full min-h-[300px] lg:max-h-max"
              image={getImage(heroImage.focalPoint.image)}
              alt={heroImage.name}
              // imgStyle={{ objectFit: "CONTAIN" }}
            />
          </>
        </Transition>
      ) : (
        ""
      )}
    </>
  );
};

export default Image;
