/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { useTheme } from "@skagami/gatsby-plugin-dark-mode";
import Header from "../components/header";
import Footer from "../components/footer";

import { Provider } from "../context/pageContext";
import HeroGalleryImages from "../components/HeroGalleryImages";

const Layout = props => {
  console.log("layout", props);
  const [theme, toggleTheme] = useTheme();

  const { children, navigationBorder = false } = props;

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Provider>
      <div className="main-container bg-no-repeat" style={{ display: "grid" }}>
        <HeroGalleryImages {...props} />

        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          navigationBorder={navigationBorder}
          theme={[theme, toggleTheme]}
        />
        <main className="pt-20 md:pt-10 lg:pt-0">
          <div>{children}</div>
        </main>

        <footer className="mt-20 md:mt-2 pt-20 md:pt-9 flex flex-col justify-between">
          <Footer />
        </footer>
      </div>
    </Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
