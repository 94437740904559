import React from "react";
import { Link } from "gatsby";
import Icon from "../images/icon.inline.svg";

const Footer = () => {
  return (
    <>
      <div className="max-w-full mx-auto">
        <footer className="p-4 bg-slate-100/90 rounded-lg rounded-b-none shadow md:flex md:items-center md:justify-between md:px-6 md:py-4 dark:bg-slate-900/90 dark:ring-4 dark:ring-slate-900/60">
          <span className="text-sm sm:text-center dark:text-slate-400 text-slate-500 font-light flex items-center">
            <div className="w-6">
              <Icon />
            </div>
            {/* © {new Date().getFullYear()} <Link to="/" className=""></Link> */}
          </span>
          <ul className="flex flex-wrap items-center mt-3 sm:mt-0 md:ml-5">
            <li>
              <a
                href="#"
                className="mr-4 text-sm text-slate-500 md:mr-6 dark:text-slate-400"
              >
                Details
              </a>
            </li>
            <li>
              <a
                href="#"
                className="mr-4 text-sm text-slate-500 md:mr-6 dark:text-slate-400"
              >
                Privacy Policy
              </a>
            </li>

            <li>
              <a
                href="#"
                className="text-sm text-slate-500 dark:text-slate-400"
              >
                Contact
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </>
  );
};

export default Footer;
