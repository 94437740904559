// import jsonServer from "../api/jsonServer";
import createDataContext from "./createDataContext";
import { graphql } from "gatsby";

const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_state":
      const newState = Object.assign({}, state, action.payload);

      return newState;

    default:
      return state;
  }
};
const setState = dispatch => {
  return (newObject, callback) => {
    dispatch({
      type: "set_state",
      payload: newObject,
    });
  };
};

export const { Context, Provider } = createDataContext(
  pageReducer,
  {
    setState,
  },
  {
    page: 0,
  }
);
