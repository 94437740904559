import React, { Fragment } from "react";
import { Link } from "gatsby";
import { Popover, Transition } from "@headlessui/react";
import { solutions } from "./links";

import { resources } from "./links";
import ThemeToggle from "./themeToggle";
import Icon from "../../images/icon.inline.svg";
import ButtonGitHub from "./buttonGitHub";
import { XMarkIcon } from "@heroicons/react/24/outline";

const MobileNav = ({ iconData, theme }) => {
  return (
    <Transition
      key="mobile-popover-nav-transition"
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        key="mobile-popover-nav-panel"
        focus
        className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden focus:outline-none"
      >
        <div className="divide-y-2 divide-slate-50 dark:divide-black dark:divide-opacity-20 rounded-lg bg-white dark:bg-slate-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-5 pt-5 pb-6">
            <div className="flex items-center justify-between">
              <div className="w-14">
                <Icon />
              </div>
              <div className="-mr-2">
                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white dark:bg-slate-800 p-2 text-slate-400 hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-800">
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid gap-y-8">
                {solutions.map(item => {
                  return (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-slate-50 dark:hover:bg-slate-800"
                    >
                      {/* Get the solution Icon */}
                      {iconData &&
                        iconData.allContentfulAsset.edges.map(
                          ({ node: { title, svg } }) => {
                            if (title === item.icon)
                              return (
                                <div
                                  key={`${title}-icon`}
                                  className="w-5 flex-shrink-0"
                                  dangerouslySetInnerHTML={{
                                    __html: svg.content,
                                  }}
                                />
                              );

                            return false;
                          }
                        )}
                      <span className="ml-3 text-base font-medium text-slate-500 dark:text-slate-400 hover:text-slate-900 dark:hover:text-slate-200">
                        {item.name}
                      </span>
                    </Link>
                  );
                })}
              </nav>
            </div>
          </div>
          <div className="space-y-6 py-6 px-5">
            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
              <Link
                to="/portfolio"
                className="text-base font-medium text-slate-500 dark:text-slate-400 hover:text-slate-900 dark:hover:text-slate-200"
              >
                Portfolio
              </Link>

              {resources.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-base font-medium text-slate-500 dark:text-slate-400 hover:text-slate-900 dark:hover:text-slate-200"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
          <div className="relative flex space-y-0 space-x-10  px-5 py-5 sm:px-8 rounded-b-lg bg-slate-50 dark:bg-slate-900">
            <div className="flex justify-around flex-auto text-sm">
              <ThemeToggle
                key="mobile_theme_toggle"
                id="mobile_theme_toggle"
                text={true}
                theme={theme}
                className="mr-5"
              />
              <ButtonGitHub text={true} />
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  );
};

export default MobileNav;
